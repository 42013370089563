import ProfileAvatar from "@/components/profile/item/profile-avatar";
import ProfileFollowsYou from "@/components/profile/profile-follows-you";
import { followerFormat } from "@/lib/utils/followerFormat";
import { hasData } from "@/lib/utils/hasData";
import { signIn, useSession } from "next-auth/client";
import Link from "next/link";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-simple-toasts";
import ProfileMenuOptions from "./profile-menu-options";
import ProfileFollow from "./profile-follow";
import { USER_PERMS } from "@/lib/config";
import AuthContext from "@/components/auth/context";
import { isBlocked } from "@/lib/utils/isBlocked";
import { idInJson } from "@/lib/utils/idInJson";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import HTag from "@/components/templateux/seo/HTag";
import {
  adminHandler,
  blockHandler,
  followApprove,
  followHandler,
  ignoreHandler,
  approveHandler,
  killHandler,
  notifyHandler,
} from "@/lib/utils/handlers/profile";
import { textProcess } from "@/lib/utils/textprocess";
import { getBitwise } from "@/lib/utils/bitoperations";
import { Decorate } from "@/lib/utils/decorate";
import { isAdminObject } from "@/lib/utils/isAdminUser";

export default function ProfileSingle({
  itemdata,
  userdata,
  ownerdata,
  shrink = false,
  owner_block_json,
  owner_name,
  owner_id, //user_id
  owner_bol_staff_help, //bol_staff_help
  owner_staff_json, //staff_json
  owner_bol_admin_help, //bol_admin_help
  placeholder,
  navtype,
  helptype,
  startPlayer,
  isProfileHeader,
}) {
  const { isConfirmed } = useConfirm();

  const authContext = React.useContext(AuthContext);

  const [user_name, set_user_name] = useState(
    hasData(itemdata?.user_name) ? itemdata?.user_name : owner_name
  );
  const [profile_link, set_profile_link] = useState(
    `/${
      hasData(itemdata?.user_name)
        ? itemdata?.user_name?.toLowerCase()
        : owner_name?.toLowerCase()
    }${itemdata?.stream_status?.toString() == "1" ? `/chat` : ``}`
  );

  const [session, loading] = useSession();
  const [state_following_them, set_state_following_them] = useState(
    itemdata?.following_them > 0 ? true : false
  );
  const [state_following_them_status, set_state_following_them_status] =
    useState(
      hasData(itemdata?.following_them_status)
        ? itemdata?.following_them_status
        : false
    );
  const [followers_total, set_followers_total] = useState(
    hasData(itemdata?.followers) ? itemdata?.followers : 0
  );
  const [isUserBlocked, set_isUserBlocked] = useState(
    isBlocked(owner_block_json, itemdata?.timestamp, itemdata?.user_name)
  );
  const [isIgnored, set_isIgnored] = useState(
    idInJson(userdata?.ignore_json, itemdata?.id)
  );
  const [isApproved, set_isApproved] = useState(
    idInJson(ownerdata?.approve_json, itemdata?.id)
  );

  const [_bol_notify_options, set_bol_notify_options] = useState(
    itemdata?.bol_notify_options || 1
  );
  const [_notify_options_bitarray, set_notify_options_bitarray] = useState(
    itemdata?.notify_options_bitarray || 32767
  );
  const [_notify_show_array, set_notify_show_array] = useState(
    itemdata?.notify_show_array || []
  );

  useEffect(() => {
    if (itemdata) {
      set_followers_total(
        hasData(itemdata?.followers) ? parseInt(itemdata?.followers) : 0
      );
      set_user_name(
        hasData(itemdata?.user_name) ? itemdata?.user_name : owner_name
      );
      set_profile_link(
        `/${itemdata?.user_name?.toLowerCase()}${
          itemdata?.stream_status?.toString() == "1" ? `/chat` : ``
        }`
      );
    }

    if (itemdata && !loading && userdata) {
      set_state_following_them(
        hasData(itemdata?.following_them)
          ? parseInt(itemdata?.following_them) > 0
          : false
      );
      set_state_following_them_status(
        hasData(itemdata?.following_them_status)
          ? parseInt(itemdata?.following_them_status)
          : 1
      );
      set_bol_notify_options(itemdata?.bol_notify_options || 1);
      set_notify_options_bitarray(itemdata?.notify_options_bitarray || 32767);
      set_notify_show_array(itemdata?.notify_show_array || []);

      set_isUserBlocked(
        isBlocked(owner_block_json, itemdata?.timestamp, itemdata?.user_name)
      );
      set_isIgnored(
        hasData(itemdata?.id) && hasData(userdata?.ignore_json)
          ? idInJson(userdata?.ignore_json, itemdata?.id)
          : false
      );
      set_isApproved(
        hasData(itemdata?.id) && hasData(itemdata?.approve_json)
          ? idInJson(itemdata?.approve_json, itemdata?.id)
          : false
      );
    }
  }, [itemdata, loading, userdata, owner_name, owner_block_json]);

  const avatar = (
    <>
      <ProfileAvatar
        // we disable the visibility of a broadcaster on public pages if they have notifications turned off...
        // the second part of this statement allows it still to be seen on their profile since we aren't
        // passing bol_send_notifications through all db calls.
        isPlaying={
          itemdata?.stream_status?.toString() == "1" &&
          (!hasData(itemdata?.bol_send_notifications) ||
            itemdata?.bol_send_notifications?.toString() == "1")
        }
        isContributor={itemdata?.contributor_level > 0}
        shrink={
          getBitwise(itemdata?.credentials_bitarray, Object.keys(USER_PERMS))[
            "broadcastlive"
          ]
            ? true
            : shrink
        }
        start_date={itemdata?.stream_start_date}
        timestamp={itemdata?.timestamp}
        placeholder={placeholder}
        bol_caster={
          getBitwise(itemdata?.credentials_bitarray, Object.keys(USER_PERMS))[
            "broadcastlive"
          ]
        }
        avatar_url={itemdata?.avatar_url}
        avatar_url_sfw={itemdata?.avatar_url_sfw}
        bol_nsfw={itemdata?.bol_nsfw}
        avatar_crdate={itemdata?.avatar_crdate}
        id={itemdata?.id}
        user_name={itemdata?.user_name}
        isOwner={owner_id?.toString() == itemdata?.id?.toString()}
        hasData={hasData(itemdata?.id)}
      />
    </>
  );

  return (
    <div className="flex items-center content-center h-24">
      <div className="flex items-center content-center flex-1">
        <div className="">
          {(itemdata?.stream_status?.toString() !== "1" ||
            !isProfileHeader) && (
            <div>
              <Link
                href={profile_link}
                className="cursor-pointer flex items-center content-center"
              >
                {avatar}
              </Link>
            </div>
          )}
          {itemdata?.stream_status?.toString() == "1" && isProfileHeader && (
            <button
              onClick={() => startPlayer(true, itemdata)}
              className="cursor-pointer flex items-center content-center"
            >
              {avatar}
            </button>
          )}
        </div>
        <div className="flex-1 ml-2">
          <div>
            <Link
              href={profile_link}
              className="cursor-pointer flex items-center content-center flex-0 
                        text-gray-100  hover:text-gray-300
                        "
            >
              {/* NAME AND CONTRIBUTOR_LEVEL */}
              <div className="flex items-center content-center">
                <HTag show={isProfileHeader} tag={"h1"}>
                  <div
                    className={`font-bold flex-0 text-sm sm:text-lg 
                    `}
                  >
                    <Decorate
                      text={user_name}
                      isDecorate={itemdata?.contributor_level > 0}
                      isReminder={false}
                    />
                  </div>
                </HTag>
              </div>
            </Link>
          </div>
          {/* FOLLOWER COUNT & TAGS */}
          {itemdata?.id?.toString() !== "0" && (
            <div className={` flex items-center content-center`}>
              <Link
                href={`/${user_name?.toLowerCase()}/followers`}
                className={`text-xs sm:text-sm md:text-md mr-1  whitespace-nowrap
                                      no-underline hover:underline
                                      text-gray-100
                      `}
              >
                {textProcess(
                  followerFormat(followers_total, "follower", "followers")
                )}
              </Link>

              {itemdata?.following_me > 0 &&
                itemdata?.following_me_status >= 1 && (
                  <div className="flex flex-0 ">
                    <Link
                      href={`/${user_name?.toLowerCase()}/following`}
                      title={`follows you`}
                    >
                      <ProfileFollowsYou />
                    </Link>
                  </div>
                )}
              {itemdata?.id?.toString() !== userdata?.id?.toString() &&
                itemdata?.following_me > 0 &&
                itemdata?.following_me_status < 1 && (
                  <div className="flex items-center content-center flex-0 ">
                    <div>
                      <ProfileFollowsYou
                        showOptions={true}
                        onAccept={() =>
                          followApprove(itemdata?.following_me, 1)
                        }
                        onReject={() =>
                          followApprove(itemdata?.following_me, 0)
                        }
                        follow_status={itemdata?.following_me_status}
                      />
                    </div>
                  </div>
                )}
            </div>
          )}
          {itemdata?.id?.toString() == "0" && <div>BOT</div>}
        </div>
      </div>

      {/* FOLLOW BUTTON & MORE */}
      <div className="flex-0 flex items-center content-center mt-0">
        {/* Loading */}
        {itemdata?.id?.toString() !== "0" && (loading || placeholder) && (
          <ProfileFollow
            classColor="bg-gray-500 text-white cursor-progress animate-pulse"
            onClick={() =>
              toast(`Still loading. Please wait a moment...`, {
                time: 3000,
                className: "",
                clickable: true,
                clickClosable: true,
              })
            }
            notifyHandler={() => {}}
            message={textProcess("Follow")}
            ownerdata={itemdata}
            show_options={false}
            _bol_notify_options={_bol_notify_options}
            set_bol_notify_options={set_bol_notify_options}
            _notify_options_bitarray={_notify_options_bitarray}
            set_notify_options_bitarray={set_notify_options_bitarray}
            _notify_show_array={_notify_show_array}
            set_notify_show_array={set_notify_show_array}
            shrink={shrink}
          />
        )}

        {/* FOLLOW - NOT LOGGED IN*/}
        {itemdata?.id?.toString() !== "0" &&
          !loading &&
          !session &&
          !placeholder && (
            <ProfileFollow
              classColor="bg-brand hover:bg-blue-600 text-white"
              onClick={signIn}
              notifyHandler={() => {}}
              message={textProcess("Follow")}
              ownerdata={itemdata}
              show_options={false}
              _bol_notify_options={_bol_notify_options}
              set_bol_notify_options={set_bol_notify_options}
              _notify_options_bitarray={_notify_options_bitarray}
              set_notify_options_bitarray={set_notify_options_bitarray}
              _notify_show_array={_notify_show_array}
              set_notify_show_array={set_notify_show_array}
              shrink={shrink}
            />
          )}

        {/* FOLLOW - LOGGED IN*/}
        {itemdata?.id?.toString() !== "0" &&
          !loading &&
          session &&
          !placeholder &&
          itemdata?.id != userdata?.id && (
            <ProfileFollow
              classColor={`${
                state_following_them
                  ? "bg-gray-500"
                  : "bg-brand hover:bg-blue-600"
              }  text-white`}
              onClick={() => {
                followHandler(
                  itemdata?.id,
                  state_following_them && state_following_them_status >= 1,
                  itemdata?.user_name,
                  isConfirmed,
                  set_state_following_them_status,
                  set_state_following_them
                );
              }}
              notifyHandler={notifyHandler}
              message={textProcess(
                state_following_them && state_following_them_status >= 1
                  ? `Following`
                  : state_following_them && state_following_them_status < 1
                  ? "Requested"
                  : "Follow"
              )}
              ownerdata={itemdata}
              show_options={
                state_following_them && state_following_them_status >= 1
              }
              _bol_notify_options={_bol_notify_options}
              set_bol_notify_options={set_bol_notify_options}
              _notify_options_bitarray={_notify_options_bitarray}
              set_notify_options_bitarray={set_notify_options_bitarray}
              _notify_show_array={_notify_show_array}
              set_notify_show_array={set_notify_show_array}
              shrink={shrink}
            />
          )}
      </div>

      {/* MENu */}
      <div className="flex-0">
        {/* MORE */}
        {itemdata?.id?.toString() !== "0" && (
          <div className="flex-0 ml-2 flex items-center content-center">
            <ProfileMenuOptions
              itemdata={itemdata}
              followApprove={followApprove}
              blockHandler={blockHandler}
              isBlocked={isUserBlocked}
              ignoreHandler={ignoreHandler}
              approveHandler={approveHandler}
              adminHandler={adminHandler}
              killHandler={killHandler}
              isIgnored={isIgnored}
              isApproved={isApproved}
              ssr_isadmin={isAdminObject(
                owner_id, //user_id
                owner_bol_staff_help, //bol_staff_help
                owner_staff_json, //staff_json
                owner_bol_admin_help, //bol_admin_help
                userdata?.id?.toString(), //my_id
                userdata?.bol_admin?.toString() //global_admin
              )}
              userdata={userdata}
              session={session}
              owner_id={owner_id}
              isConfirmed={isConfirmed}
              authContext={authContext}
              set_isIgnored={set_isIgnored}
              set_isApproved={set_isApproved}
              profiledata={ownerdata}
            />
          </div>
        )}
      </div>
    </div>
  );
}
