import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { USER_PERMS } from "@/lib/config";
import { getBitwise } from "@/lib/utils/bitoperations";
import { adminHandler } from "@/lib/utils/handlers/profile";
import { hasData } from "@/lib/utils/hasData";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";

export const AdminModal = ({
  isOpen,
  closeModal,
  profiledata,
  ssr_data_tribe,
  userdata,
  itemdata,
  session,
}) => {
  const { isConfirmed } = useConfirm();

  const [isSiteAdmin, set_isSiteAdmin] = useState(userdata?.bol_admin);

  if (userdata?.bol_admin?.toString() !== "1") return <></>;

  return (
    <>
      <Transition appear show={isOpen == "admin"} as={Fragment}>
        <Dialog
          as="div"
          open={isOpen == "admin"}
          className="fixed inset-0 overflow-y-scroll bg-[rgb(0,0,0,0.85)]"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle  z-[2000]"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className=" inline-block max-w-7xl w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                        bg-gray-900 border-gray-700"
              >
                <div className="text-xl mb-4 font-bold">
                  Admin options for{" "}
                  <Link
                    href={`/${itemdata?.user_name}`}
                    className="underline hover:no-underline"
                  >
                    {itemdata?.user_name}
                  </Link>
                </div>

                <div className="items-center content-center">
                  <div className="flex-1 flex-wrap flex">
                    {/* RESET AVATAR */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "avatar",
                          "reset avatar",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={"Reset Avatar"}
                    />

                    {/* CONTRIBUTION */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "contribution",
                          !hasData(itemdata?.contribution_date)
                            ? "Add Contribution"
                            : "Remove contribution",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={
                        !hasData(itemdata?.contribution_date)
                          ? "Add Contribution"
                          : "Remove contribution"
                      }
                    />

                    {/* CONTRIBUTOR LEVEL */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "contributor",
                          itemdata?.contributor_level?.toString() == "0"
                            ? "Add Contributor"
                            : "Remove contributor",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={
                        itemdata?.contributor_level?.toString() == "0"
                          ? "+ContributorLevel"
                          : "-ContributorLevel"
                      }
                    />

                    {/* BROADCAST */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "broadcast",
                          `${
                            !getBitwise(
                              userdata?.credentials_bitarray,
                              Object.keys(USER_PERMS)
                            )["broadcastlive"]
                              ? "Grant"
                              : "Remove"
                          } broadcast credentials`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        !getBitwise(
                          userdata?.credentials_bitarray,
                          Object.keys(USER_PERMS)
                        )["broadcastlive"]
                          ? "Grant"
                          : "Remove"
                      } Broadcast`}
                    />

                    {/* POSTS */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "post",
                          `${
                            !getBitwise(
                              itemdata?.credentials_bitarray,
                              Object.keys(USER_PERMS)
                            )["post"]
                              ? "Grant"
                              : "Remove"
                          } post credentials`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        !getBitwise(
                          itemdata?.credentials_bitarray,
                          Object.keys(USER_PERMS)
                        )["post"]
                          ? "Grant"
                          : "Remove"
                      } Posts`}
                    />

                    {/* RECOMMEND */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "recommend",
                          `${
                            itemdata?.bol_recommended?.toString() == "0"
                              ? "Add to"
                              : "Remove from"
                          } Recommended`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_recommended?.toString() == "0"
                          ? "Add to"
                          : "Remove from"
                      } Recommended`}
                    />

                    {/* CREATOR */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "creator",
                          `${
                            itemdata?.bol_creator?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } Creator`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_creator?.toString() == "0"
                          ? "Add"
                          : "Remove"
                      } Creator`}
                    />

                    {/* SOuNDBOARDS */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "soundboards",
                          `${
                            itemdata?.bol_soundboards?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } Soundboards`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_soundboards?.toString() == "0"
                          ? "Add"
                          : "Remove"
                      } Soundboards`}
                    />

                    {/* NSFW AVATAR */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "nsfw",
                          `${
                            itemdata?.bol_nsfw?.toString() == "1"
                              ? "Show"
                              : "Hide"
                          } NSFW Avatar`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_nsfw?.toString() == "1" ? "Show" : "Hide"
                      } NSFW Avatar`}
                    />

                    {/* NSFW_SHOW */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "nsfw_show",
                          `${
                            itemdata?.bol_nsfw_show?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } NSFW Rating`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_nsfw_show?.toString() == "0"
                          ? "Add"
                          : "Remove"
                      } NSFW Show Rating`}
                    />

                    {/* DISCORD */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "discord",
                          `${
                            itemdata?.bol_discord_announce?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } Discord Announcements`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_discord_announce?.toString() == "0"
                          ? "Add"
                          : "Remove"
                      } Discord Announcements`}
                    />

                    {/* FEATURED */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "feature",
                          `${
                            itemdata?.bol_featured?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } Featured`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_featured?.toString() == "0"
                          ? "Add"
                          : "Remove"
                      } Featured`}
                    />

                    {/* VERIFY */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "verify",
                          `${
                            itemdata?.verified?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } Verified Status`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.verified?.toString() == "0" ? "Add" : "Remove"
                      } Verified`}
                    />

                    {/* SEO */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "directory",
                          `${
                            itemdata?.bol_directory?.toString() == "0"
                              ? "Add to"
                              : "Remove from"
                          } Directory`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_directory?.toString() == "0"
                          ? "Add to"
                          : "Remove from"
                      } Directory`}
                    />

                    {/* SEO */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "seo",
                          `${
                            itemdata?.bol_seo?.toString() == "0"
                              ? "Add"
                              : "Remove"
                          } SEO`,
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={`${
                        itemdata?.bol_seo?.toString() == "0" ? "Add" : "Remove"
                      } SEO`}
                    />

                    {/* COOLDOWN */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "cooldown",
                          "reset the cooldown",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={"Reset Cooldown"}
                    />

                    {/* KILL */}
                    <PopupMenuOption
                      isDisplay={
                        session &&
                        isSiteAdmin &&
                        itemdata?.stream_status?.toString() == "1"
                      }
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "kill",
                          "kill the live stream",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={"Admin Kill Stream"}
                    />

                    {/* BAN */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "ban",
                          "ban the account",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={"Ban Account"}
                    />

                    {/* DELETE */}
                    <PopupMenuOption
                      isDisplay={session && isSiteAdmin}
                      onClick={() => {
                        adminHandler(
                          itemdata?.id,
                          "delete",
                          "delete the account",
                          itemdata?.user_name,
                          isConfirmed
                        );
                      }}
                      icon="lock-closed-sm"
                      title={"Delete Account"}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
