import { textProcess } from "./textprocess";

export function Decorate({
  text,
  isDecorate,
  isReminder = false,
  dashClass = `absolute w-full h-[2px] bg-gradient-to-r rounded-full`,
}) {
  if (!isDecorate) return text;
  return (
    <>
      <div className="relative inline-block group">
        <div className="">{textProcess(text)}</div>
        <div
          title={`Premium account`}
          className={`${dashClass} group-hover:animate-kitleft
            
            ${
              isReminder
                ? "from-[#ffffff00] via-[#ffffffaa] to-[#ffffffFF]"
                : "from-[#dc262600] via-[#dc2626aa] to-[#dc2626FF]"
            }
            
            `}
        />
        <div
          title={`Premium account`}
          className={`${dashClass} invisible group-hover:visible group-hover:animate-kitright
            
            ${
              isReminder
                ? "from-[#ffffff00] via-[#ffffffaa] to-[#ffffffFF]"
                : "from-[#dc2626ff] via-[#dc2626aa] to-[#dc262600]"
            }
            
            `}
        />
      </div>
    </>
  );
}
