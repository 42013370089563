import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";

import { blockactions } from "@/lib/config";
import { Fragment, useContext, useRef } from "react";
import Popup from "reactjs-popup";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { useRouter } from "next/router";
import { AdminModal } from "../admin/AdminModal";
import { TribeContext } from "@/components/context/tribe-context";
import { signIn } from "next-auth/client";
import { ModalContext } from "@/components/context/modal-context";

export default function ProfileMenuOptions({
  itemdata,
  followApprove,
  blockHandler,
  isBlocked,
  ignoreHandler,
  approveHandler,
  adminHandler,
  killHandler,
  isIgnored,
  isApproved,
  ssr_isadmin,
  userdata,
  session,
  owner_id,
  isConfirmed,
  authContext,
  set_isIgnored,
  set_isApproved,
  profiledata,
}) {
  const ref = useRef(null);
  const ssr_data_tribe = useContext(TribeContext);
  const { isOpen, set_isOpen } = useContext(ModalContext);
  const closeModal = () => {
    closeMenu();
    set_isOpen(null);
  };
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {
    if (ref.current) ref.current.close();
  };
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};
  const router = useRouter();

  if (!session)
    return (
      <div className="menu flex items-center content-center  flex-0  bg-gray-900 ">
        <div
          aria-haspopup="true"
          className="menu-item w-5 h-5 flex-0 "
          onClick={() => signIn()}
        >
          <SvgJsx
            type="outline"
            icon="dots-v"
            className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                         text-gray-400  hover:text-gray-200`}
            title="More"
          />
        </div>
      </div>
    );

  return (
    <>
      <div className="menu flex items-center content-center  flex-0  bg-gray-900 ">
        <Popup
          trigger={
            <div aria-haspopup="true" className="menu-item w-5 h-5 flex-0 ">
              <SvgJsx
                type="outline"
                icon="dots-v"
                className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                                   text-gray-400  hover:text-gray-200`}
                title="More"
              />
            </div>
          }
          position="left top"
          on="click"
          closeOnDocumentClick
          closeOnEscape
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{
            padding: "0px",
            border: "none",
            backgroundColor: "transparent",
            zIndex: 999,
          }}
          arrow={false}
          nested
          ref={ref}
        >
          <div
            className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2 z-[1000]
                     bg-gray-800 "
          >
            {/* GOTO PROFILE */}
            <PopupMenuOption
              isDisplay={true}
              onClick={() => {
                closeMenu();
                router.push(`/${itemdata?.user_name}`);
              }}
              icon="profile-sm"
              title="Profile"
            />

            {/* SETTINGS */}

            <PopupMenuOption
              isDisplay={
                userdata?.id?.toString() == itemdata?.id?.toString() ||
                ssr_isadmin[`profile`] ||
                ssr_isadmin[`chat`] ||
                ssr_isadmin[`broadcast`] ||
                ssr_isadmin[`api`] ||
                ssr_isadmin[`staff`]
              }
              onClick={() => {
                closeMenu();
                if (userdata?.id?.toString() == itemdata?.id?.toString()) {
                  router.push(`/${itemdata?.user_name}/settings`);
                  return;
                }
                if (ssr_isadmin[`profile`]) {
                  router.push(`/${itemdata?.user_name}/settings/profile`);
                  return;
                }
                if (ssr_isadmin[`chat`]) {
                  router.push(`/${itemdata?.user_name}/settings/chat`);
                  return;
                }
                if (ssr_isadmin[`broadcast`]) {
                  router.push(`/${itemdata?.user_name}/settings/broadcast`);
                  return;
                }
                if (ssr_isadmin[`api`]) {
                  router.push(`/${itemdata?.user_name}/settings/api`);
                  return;
                }
                if (ssr_isadmin[`staff`]) {
                  router.push(`/${itemdata?.user_name}/settings/staff`);
                  return;
                }
                router.push(`/${itemdata?.user_name}/settings`);
                return;
              }}
              icon="settings-sm"
              title={"Settings"}
            />

            {/* APPROVE FOLLOW REQUEST */}
            <PopupMenuOption
              isDisplay={
                session &&
                itemdata?.following_me > 0 &&
                itemdata?.following_me_status < 1
              }
              onClick={() => {
                closeMenu();
                followApprove(itemdata?.following_me, 1);
              }}
              icon="eye-sm"
              title={`Approve Follower`}
            />

            <PopupMenuOption
              isDisplay={
                session &&
                itemdata?.following_me > 0 &&
                itemdata?.following_me_status < 1
              }
              onClick={() => {
                closeMenu();
                followApprove(itemdata?.following_me, 0);
              }}
              icon="eye-sm"
              title={`Reject Follower`}
            />

            <PopupMenuOption
              isDisplay={
                session &&
                itemdata?.following_me > 0 &&
                itemdata?.following_me_status >= 1
              }
              onClick={() => {
                closeMenu();
                followApprove(itemdata?.following_me, 0);
              }}
              icon="eye-sm"
              title={`Remove Follower`}
            />

            {/* APPROVE */}
            <PopupMenuOption
              isDisplay={ssr_isadmin[`chat`]} //&& (userdata?.id == owner_id)) }
              onClick={() => {
                closeMenu();
                approveHandler(
                  itemdata?.id,
                  isApproved ? "0" : "1",
                  itemdata?.user_name,
                  isConfirmed,
                  authContext,
                  set_isApproved
                );
              }}
              icon="x-circle-sm"
              title={isApproved ? "Un-Approve" : "Approve"}
            />

            {/* IGNORE */}
            <PopupMenuOption
              isDisplay={userdata?.id?.toString() !== itemdata?.id?.toString()} //&& (userdata?.id == owner_id)) }
              onClick={() => {
                closeMenu();
                ignoreHandler(
                  itemdata?.id,
                  isIgnored ? "0" : "1",
                  itemdata?.user_name,
                  isConfirmed,
                  authContext,
                  set_isIgnored
                );
              }}
              icon="x-circle-sm"
              title={isIgnored ? "Un-Ignore" : "Ignore"}
            />

            {/* UNBLOCK */}

            <PopupMenuOption
              isDisplay={
                ssr_isadmin[`chat`] &&
                userdata?.id?.toString() !== itemdata?.id?.toString() &&
                isBlocked
              }
              onClick={() => {
                closeMenu();
                blockHandler(
                  owner_id,
                  itemdata?.id,
                  itemdata?.user_name,
                  -1,
                  isConfirmed
                );
              }}
              icon="x-circle-sm"
              title={"Unblock"}
            />

            {/* BLOCK */}
            {blockactions
              .filter(
                (e) =>
                  ["0", "1", "2", "3", "4", "5", "6"].indexOf(
                    e.action.toString()
                  ) >= 0
              )
              .map((e, index) => (
                <Fragment key={index}>
                  <PopupMenuOption
                    isDisplay={
                      ssr_isadmin[`chat`] &&
                      userdata?.id?.toString() !== itemdata?.id?.toString() &&
                      itemdata?.id?.toString() !== owner_id?.toString() &&
                      !isBlocked
                    }
                    onClick={() => {
                      closeMenu();
                      blockHandler(
                        owner_id,
                        itemdata?.id,
                        itemdata?.user_name,
                        e.action,
                        isConfirmed
                      );
                    }}
                    icon="x-circle-sm"
                    title={e.button_msg}
                  />
                </Fragment>
              ))}

            {/* KILL */}
            <PopupMenuOption
              isDisplay={
                session &&
                ssr_isadmin[`broadcast`] &&
                itemdata?.stream_status?.toString() == "1"
              }
              onClick={() => {
                closeMenu();
                killHandler(itemdata?.id, itemdata?.user_name);
              }}
              icon="lock-closed-sm"
              title={"Kill Stream"}
            />

            {/* {isAdmin && 
      <>
        <Popup
          trigger={
            
                  <div 
                    aria-haspopup="true"
                    className="menu-item "
                    > 
                     <PopupMenuOption
                        isDisplay={((isAdmin && userdata?.id?.toString() !== itemdata?.id?.toString() && itemdata?.id?.toString() !== owner_id?.toString()) && !isBlocked)} //&& (userdata?.id == owner_id)) }
                        icon="scale-sm"
                        title={'User Moderation'} 
                      />
                    </div>}
          position="left center"
          on="click"
          closeOnDocumentClick
          closeOnEscape
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent' }}
          arrow={false}
          nested
          ref={ref}
        >
          <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                       bg-gray-800 "
          >

           
                        {/* BLOCK */}
            {/* {blockactions.filter((e)=>['0','1','2','3','4','5','6'].indexOf(e.action.toString()) >= 0).map((e,index) => 
                          <Fragment key={index}>
                            <PopupMenuOption
                              isDisplay={((isAdmin && userdata?.id?.toString() !== itemdata?.id?.toString() && itemdata?.id?.toString() !== owner_id?.toString()) && !isBlocked)}
                              onClick={()=>{
                                closeMenu();
                                blockHandler(owner_id,itemdata?.id,itemdata?.user_name,e.action,isConfirmed)}}
                              icon="x-circle-sm"
                              title={e.button_msg} 
                            />
                          </Fragment>
                          )}

          </div>
        </Popup>
      </> 
      } */}

            {/* ADMIN STUFF */}

            {/*TEST*/}
            <PopupMenuOption
              isDisplay={session && ssr_isadmin[`global`]}
              onClick={() => {
                closeMenu();
                set_isOpen("admin");
              }}
              icon="lock-closed-sm"
              colorClass={`bg-gray-900 hover:bg-gray-700 hover:text-red-500 text-red-600 `}
              title={"Admin Settings"}
            />
          </div>
        </Popup>
      </div>
      <AdminModal
        isOpen={isOpen}
        closeModal={closeModal}
        profiledata={profiledata}
        ssr_data_tribe={ssr_data_tribe}
        userdata={userdata}
        itemdata={itemdata}
        session={session}
      />
    </>
  );
}
