import AvatarFlairSizes from "@/components/templateux/user/avatar-flair-sizes";
import { ProfileTimer } from "./profile-timer";

export default function ProfileAvatar({
  isPlaying,
  isContributor,
  shrink = false,
  start_date,
  timestamp,
  placeholder,
  bol_caster = true,
  avatar_url,
  avatar_url_sfw,
  bol_nsfw,
  avatar_crdate,
  id,
  user_name,
  isOwner = false,
  hasData = true,
}) {
  return (
    <>
      <div
        className={`flex flex-col items-center content-center  justify-center text-center relative group
                    
                  `}
      >
        <div className="flex flex-col flex-0 bg-red ">
          <div className="block">
            <div
              className={`rounded-full ${
                isContributor ? "bg-white" : "bg-gray-500 opacity-90"
              } p-[2px]`}
            >
              <div className="flex h-full w-full items-center justify-center back">
                <AvatarFlairSizes
                  isPlaying={isPlaying}
                  isContributor={isContributor}
                  isBot={false}
                  avatar_url={avatar_url}
                  avatar_url_sfw={avatar_url_sfw}
                  bol_nsfw={bol_nsfw}
                  avatar_crdate={avatar_crdate}
                  id={id}
                  user_name={user_name}
                  size={"xl"}
                />
              </div>
            </div>
          </div>
        </div>
        {bol_caster && (
          <div>
            <ProfileTimer
              shrink={shrink}
              isPlaying={isPlaying}
              start_date={start_date}
              timestamp={timestamp}
            />
          </div>
        )}
      </div>
    </>
  );
}
